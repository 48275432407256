<template>
  
  <div class="container inhaler bg-light" :class="!isPWA ? 'inhaler--not-pwa' : ''">
    <div class="container_main">
      <div class="calibration" v-if="isCalibration">
        
        <b-form-input class="calibration_range" type="range" v-model="rangeValue" min="0" max="1" step="0.01"></b-form-input>
        
        <div class="calibration_stats">

          <b-button class="mb-4 unclickable" variant="primary">
            <span class="btn_label">Resistance</span><br>
            <span class="btn_value">
              <span class="">{{Math.round(selectedDevice.resistanceFactor * 100)}}%</span>
              <span class="btn_label">
                <span class="text-muted btn_separator">/ 100% </span>
              </span>
            </span>
          </b-button>

          <b-button class="unclickable" variant="outline-primary">
            <span class="btn_label">Current Flow</span><br>
            <span class="btn_value">
              <span class="text-secondary">{{flowDampened}}</span>
              <span class="btn_label">
                <span class="text-muted btn_separator">/ {{flow}} </span>
                
              </span>
            </span>
          </b-button>

          <b-button class="unclickable" variant="outline-primary">
            <span class="btn_label">Max Flow</span><br>
            <span class="btn_value">
              <span class="text-secondary">{{maxFlowDampened}}</span>
              <span class="btn_label">
                <span class="text-muted btn_separator">/ {{maxFlow}} </span>
                
              </span>
            </span>
          </b-button>

          <b-button class="mb-4" variant="secondary" @click="$store.dispatch('inhaler/clearFlow')">
            Clear<br>Max Flow
          </b-button>

          <b-button class="unclickable" variant="outline-primary">
            <span class="btn_label text-danger">Ambient</span><br>
            <span class="btn_value">
              <span class="text-danger">{{ambientDampened}}</span>
              <span class="btn_label">
                <span class="text-muted btn_separator">/ {{ambient}} </span>
                
              </span>
            </span>
          </b-button>

          <b-button variant="danger" @click="$router.push('/main/countdown')">
            Recalculate Ambient
          </b-button>
        </div>

      </div>
      <scale :isCalibration="isCalibration" :showZones="true" :opt-min="selectedDevice.optimalMin" :opt-max="selectedDevice.optimalMax" :value="flowDampened" :max="maxFlowDampened" :ambient="ambientDampened"></scale>
    </div>
    <div class="container_footer p-3 bg-primary text-white">

      <div class="d-flex align-items-center">

        <div class="font-weight-bold flex-grow-1 d-flex align-items-end">
          <div class="flex-grow-1" style="font-size:5rem; line-height:0.75; font-weight: 600;">
            {{maxFlowDampened}}
          </div>
          <div class="mr-3" style="line-height: 1.3;" >
            {{selectedDevice.name}}<br>
            L/min
          </div>
        </div>

        <b-button variant="secondary" style="line-height: 1.3;" class="p-3">
          Optimal<br>
          Inspiratory Flow<br>
          <strong>{{selectedDevice.optimalMin}}-{{selectedDevice.optimalMax}} L/min</strong>
        </b-button>

      </div>

      <b-button variant="light" class="d-block w-100 p-2 mt-3" @click="viewResults()">View results</b-button>
    </div>
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import Scale from "~/components/Scale.vue"
import AudioAnalyser from "~/js/AudioAnalyser.js"

export default {
	components: {
    Scale,
  },

  data () {
    return {
      analyser: null,

      resetTO: null,
      clearDelay: 5000,
    }
  },

  computed: {
    ...mapGetters({
      stream: 'inhaler/stream',
      selectedDevice: 'inhaler/selectedDevice',
      flow: 'inhaler/flow',
      maxFlow: 'inhaler/maxFlow',
      ambient: 'inhaler/ambient',
      flowDampened: 'inhaler/flowDampened',
      maxFlowDampened: 'inhaler/maxFlowDampened',
      ambientDampened: 'inhaler/ambientDampened',
      resistanceFactor: 'inhaler/resistanceFactor',
      isPWA: 'inhaler/isPWA',
    }),
    isCalibration() {
      // return this.selectedDevice.isCalibration

      // const urlParams = new URLSearchParams(window.location.search)
      // const calibrationParam = urlParams.get('calibration')
      // return calibrationParam;

      return false;
    },
    timeoutEnabled() {
      return !this.isCalibration
    },
    resetTreshold() {
      return this.ambient
    },
    rangeValue: {
      get: function () {
        return this.resistanceFactor
      },
      set: function (newValue) {
        this.$store.dispatch('inhaler/setResistanceFactor', newValue)
      }
    },
  },

  created() {
    this.initialize()
  },

  destroyed() {
    this.analyser.stop()

    if (this.resetTO != null) {
        clearTimeout(this.resetTO)
        this.resetTO = null
    }
  },

  methods: {

    async initialize() {

      await this.$store.dispatch('inhaler/fetchAudioStream')

      this.analyser = new AudioAnalyser(this.stream, this.analyserSetValueHandler)
      this.analyser.init()
      this.analyser.start()
    },

    analyserSetValueHandler(audioLevel) {
      const flow = Math.floor(audioLevel);
      this.$store.dispatch('inhaler/setFlow', flow)

      if (this.analyser._active && flow > this.resetTreshold && this.timeoutEnabled) {
        if (this.resetTO != null) {
          clearTimeout(this.resetTO)
          this.resetTO = null
        }
        this.resetTO = setTimeout(() => {  
          this.$store.dispatch('inhaler/clearFlow')
        }, this.clearDelay)
      }
    },

    viewResults() {
      if (this.analyser) this.analyser.stop()
      this.$router.push('/main/result')
    }

  },
}

</script>

<style lang="scss">
.inhaler {
  height: 100%;
  // &--not-pwa {
  //   height: calc(100vh - 45px);
  //   min-height: 0;
  // }

  padding-top: 2.25rem;

  .calibration {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;

    &_range {
      width: calc(100% - 11rem);
      margin: 1.5rem 1rem 1rem;
    }

    &_stats {
      position: absolute;
      top: 0;
      right: 0;
      margin-left: auto;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      width: 9rem;
    }

    .btn {
      line-height: 1.1;
      display: block;
      margin-top: 0.5rem;
      border-color: transparent !important;
      &.unclickable {
        pointer-events: none;
      }
      &_label {
        font-size: 0.8rem;
      }
      &_value {
        display: block;
        font-size: 1.2rem;
        font-weight: 600;
        padding-top: 0.2rem;
      }
      &_separator {
          font-weight: 400;
          padding-left: 0.25em;
      }
    }
  }

}
</style>
<template>
  <div class="scale h-100">

    <div v-if="showZones" class="scale_zone" 
    :style="'height: '+ (toPercent(scaleMax)-toPercent(optMax)) +'%; bottom: '+ toPercent(optMax) +'%;'">
      <span :style="isCalibration ? 'padding-right:7rem;' : ''"><strong>Non-optimal</strong> Flow</span>
    </div>
    <div v-if="showZones" class="scale_zone scale_zone--optimal text-white bg-secondary" 
    :style="'height: '+ (toPercent(optMax)-toPercent(optMin)) +'%; bottom: '+ toPercent(optMin) +'%;'">
      <span :style="isCalibration ? 'padding-right:7rem;' : ''"><strong>Optimal</strong> Flow</span>
    </div>
    <div v-if="showZones" class="scale_zone" 
    :style="'height: '+ (toPercent(optMin)-toPercent(scaleMin)) +'%; bottom: '+ toPercent(scaleMin) +'%;'">
      <span :style="isCalibration ? 'padding-right:7rem;' : ''"><strong>Non-optimal</strong> Flow</span>
    </div>


    <!-- <div class="scale_value bg-danger"
    :style="'height: '+ (toPercent(value)-toPercent(scaleMin)) +'%; bottom: '+ toPercent(scaleMin) +'%;'">
    </div> -->

    <div class="scale_max bg-primary"
    :style="'bottom: '+ toPercent(max) +'%;'"
    ></div>

    <div v-if="isCalibration" class="scale_ambient bg-danger"
    :style="'bottom: '+ toPercent(ambient) +'%;'"
    ></div>


    <div class="scale_value" :style="'clip-path: inset('+ (toPercent(scaleMax)-toPercent(value)) +'% 0 0 0);'">
      <div class="scale_zone text-white bg-primary" 
      :style="'height: '+ (toPercent(scaleMax)-toPercent(optMax)) +'%; bottom: '+ toPercent(optMax) +'%;'">
        <span v-if="showZones" :style="isCalibration ? 'padding-right:7rem;' : ''"><strong>Non-optimal</strong> Flow</span>
      </div>
      <div class="scale_zone scale_zone--optimal text-white bg-primary" 
      :style="'height: '+ (toPercent(optMax)-toPercent(optMin)) +'%; bottom: '+ toPercent(optMin) +'%;'">
        <span v-if="showZones" :style="isCalibration ? 'padding-right:7rem;' : ''"><strong>Optimal</strong> Flow</span>
      </div>
      <div class="scale_zone text-white bg-primary" 
      :style="'height: '+ (toPercent(optMin)-toPercent(scaleMin)) +'%; bottom: '+ toPercent(scaleMin) +'%;'">
        <span v-if="showZones" :style="isCalibration ? 'padding-right:7rem;' : ''"><strong>Non-optimal</strong> Flow</span>
      </div>
    </div>


    <div class="scale_bar h-100">
      <div 
      v-for="label in labels"
      :key="label"
      class="scale_bar_item"
      :style="'bottom: '+ toPercent(label) +'%;'"
      >
        {{label}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'optMin',
    'optMax',
    'value',
    'max',
    'ambient',
    'showZones',
    'isCalibration',
  ],

  data () {
    return {
      scaleMin: 10,
      scaleMax: 120,
      step: 10,
    }
  },

  computed: {
    labels() {
      let labels = []
      const count = (this.scaleMax - this.scaleMin) / this.step
      for (let i=1; i<count+1; i++) {
        labels.push(this.scaleMin + i*this.step)
      }
      return labels
    },
  },

  created() {



  },

  methods: {
    toPercent(value) {
      if (value < this.scaleMin) return 0
      return (value-this.scaleMin)/(this.scaleMax-this.scaleMin)*100
    },
  },

}
</script>

<style lang="scss">
  .scale {
    position: relative;
    &_bar {
      position: absolute;
      z-index: 2;
      width: 2.25rem;
      left: 1rem;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: -1.125rem;
        background-color: white;
      }
      &_item {
        width: 100%;
        position: absolute;
        transform: translateY(50%);

        text-align: center;
        font-size: 0.85rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 0.25rem;
          height: 2px;
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          background-color: white;
        }
      }
    }

    &_zone {
      width: 100%;
      text-align: center;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 1.5rem;

      &--optimal {
        border-top: 1px solid rgba(#4dacaa, 0.25);
        border-bottom: 1px solid rgba(#4dacaa, 0.25);
      }
    }

    &_value {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      // transition: clip-path 0.1s ease-in-out;
    }

    &_max,
    &_ambient {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      transform: translateY(50%);
    }
    &_ambient {
      height: 2px;
      z-index: 1;
    }
  }
</style>